import * as React from "react";
import { graphql } from "gatsby";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { getImage } from "gatsby-plugin-image";

// Components
import Seo from "../../components/SEO";

// Template
import ProductPage from "../../templates/product-page";

// Assets
import Customers1Icon from "../../images/icons/zeal-customers-1.svg";
import Customers2Icon from "../../images/icons/zeal-customers-2.svg";
import Customers3Icon from "../../images/icons/zeal-customers-3.svg";
import Merchants1Icon from "../../images/icons/zeal-merchants-1.svg";
import Merchants2Icon from "../../images/icons/zeal-merchants-2.svg";
import Merchants3Icon from "../../images/icons/zeal-merchants-3.svg";
import Plan1Icon from "../../images/icons/product-plans-1.svg";
import Plan2Icon from "../../images/icons/product-plans-2.svg";
import Plan3Icon from "../../images/icons/product-plans-3.svg";



const ProductsPage = ({ data: { heroSectionImage, featuresSectionImages, relatedBlogs } }) => {
    const { t } = useTranslation();
    return (
        <ProductPage
            heroSection={{
                title: <Trans i18nKey="zeal.heroSection.title"
                    values={{ highlight: t("zeal.heroSection.title.highlight") }} components={[<span />]} />,
                description: t("zeal.heroSection.description"),
                button: t("zeal.heroSection.button"),
                image: heroSectionImage,
            }}
            featuresSection={{
                title: t("zeal.featuresSection.title"),
                description: t("zeal.featuresSection.description"),
                features: [
                    {
                        title: t("zeal.featuresSection.transactionalData.title"),
                        description: t("zeal.featuresSection.transactionalData.description"),
                        image: getImage(featuresSectionImages.edges[0].node.childImageSharp),
                    },
                    {
                        title: t("zeal.featuresSection.loyalty.title"),
                        description: t("zeal.featuresSection.loyalty.description"),
                        image: getImage(featuresSectionImages.edges[1].node.childImageSharp),
                    },
                    {
                        title: t("zeal.featuresSection.analytics.title"),
                        description: t("zeal.featuresSection.analytics.description"),
                        image: getImage(featuresSectionImages.edges[2].node.childImageSharp),
                    },

                ],
                button: {
                    text: t("zeal.featuresSection.button"),
                    to: "/contact-us",
                }
            }}
            howItWorksSection={{
                title: t("zeal.howItWorks.title"),
                toggleSwitch: [t("zeal.howItWorks.switcher.item1"), t("zeal.howItWorks.switcher.item2")],
                items: [
                    [
                        {
                            icon: <Customers1Icon />,
                            title: t("zeal.howItWorks.customers.pay.title"),
                            description: t("zeal.howItWorks.customers.pay.description"),
                        },
                        {
                            icon: <Customers2Icon />,
                            title: t("zeal.howItWorks.customers.earn.title"),
                            description: t("zeal.howItWorks.customers.earn.description"),
                        },
                        {
                            icon: <Customers3Icon />,
                            title: t("zeal.howItWorks.customers.loyalty.title"),
                            description: t("zeal.howItWorks.customers.loyalty.description"),
                        },
                    ],
                    [
                        {
                            icon: <Merchants1Icon />,
                            title: t("zeal.howItWorks.merchants.know.title"),
                            description: t("zeal.howItWorks.merchants.know.description"),
                        },
                        {
                            icon: <Merchants2Icon />,
                            title: t("zeal.howItWorks.merchants.loyalty.title"),
                            description: t("zeal.howItWorks.merchants.loyalty.description"),
                        },
                        {
                            icon: <Merchants3Icon />,
                            title: t("zeal.howItWorks.merchants.grow.title"),
                            description: t("zeal.howItWorks.merchants.grow.description"),
                        },
                    ],
                ],
                button: t("zeal.howItWorks.button")
            }}
            pricingSection={{
                title: t("zeal.pricing.title"),
                description: t("zeal.pricing.description"),
                switcher: {
                    text: t("zeal.pricing.switcher.text"),
                    yearly: t("zeal.pricing.plans.subtitle.yearly"),
                    monthly: t("zeal.pricing.plans.subtitle.monthly"),
                },
                plans: [
                    {
                        icon: <Plan1Icon />,
                        title: t("zeal.pricing.plans1.title"),
                        price: {
                            monthly: t("zeal.pricing.plans1.price.monthly"),
                            yearly: t("zeal.pricing.plans1.price.yearly"),
                        },

                        features: [
                            t("zeal.pricing.plans1.features1"),
                            t("zeal.pricing.plans1.features2"),
                            t("zeal.pricing.plans1.features3"),
                            t("zeal.pricing.plans1.features4"),
                            t("zeal.pricing.plans1.features5"),
                        ],
                    },
                    {
                        icon: <Plan2Icon />,
                        title: t("zeal.pricing.plans2.title"),
                        price: {
                            monthly: t("zeal.pricing.plans2.price.monthly"),
                            yearly: t("zeal.pricing.plans2.price.yearly"),
                        },

                        features: [
                            t("zeal.pricing.plans2.features1"),
                            t("zeal.pricing.plans2.features2"),
                            t("zeal.pricing.plans2.features3"),
                            t("zeal.pricing.plans2.features4"),
                            t("zeal.pricing.plans2.features5"),
                        ],
                    },
                    {
                        icon: <Plan3Icon />,
                        title: t("zeal.pricing.plans3.title"),
                        price: {
                            monthly: t("zeal.pricing.plans3.price.monthly"),
                            yearly: t("zeal.pricing.plans3.price.yearly"),
                        },

                        features: [
                            t("zeal.pricing.plans3.features1"),
                            t("zeal.pricing.plans3.features2"),
                            t("zeal.pricing.plans3.features3"),
                            t("zeal.pricing.plans3.features4"),
                            t("zeal.pricing.plans3.features5"),
                        ],
                    },
                ],
                button: t("zeal.pricing.button")
            }}
            relatedBlogsSection={{
                title: t("zeal.relatedBlogs.title"),
                description: t("zeal.relatedBlogs.description"),
                blogs: relatedBlogs.nodes
            }}
            faqSection={{
                title: t("zeal.faq.title"),
                description: t("zeal.faq.description"),
                FAQs: [
                    {
                        question: t("zeal.faq.question1"),
                        answer: t("zeal.faq.answer1"),
                    },
                    {
                        question: t("zeal.faq.question2"),
                        answer: t("zeal.faq.answer2"),
                    },
                    {
                        question: t("zeal.faq.question3"),
                        answer: t("zeal.faq.answer3"),
                    },
                    {
                        question: t("zeal.faq.question4"),
                        answer: t("zeal.faq.answer4"),
                    }
                ],
                buttonLink: '/contact-us',
                buttonText: t("zeal.relatedBlogs.button")
            }}
            yellowSection={{
                title: t("zeal.yellowSection.title"),
                description: t("zeal.yellowSection.description"),
                to: '/contact-us',
                btnText: t("zeal.yellowSection.button")
            }}
        />
    );
};

export default ProductsPage;
export const query = graphql`
            query ($language: String!) {
                locales: allLocale(
            filter: {
                ns: { in: ["common", "zeal"] }
            language: {eq: $language }
            }
            ) {
                edges {
                node {
                ns
                data
            language
        }
      }
    }
        heroSectionImage: file(
        name: {regex: "/zeal-hero/"}
        ) {
            childImageSharp {
            gatsbyImageData(
                placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
        )
      }
    }
        featuresSectionImages: allFile(
        filter: {sourceInstanceName: {eq: "images"}, name: {regex: "/zeal-use-case/"}}
        sort: {order: ASC, fields: name}
        ) {
            edges {
            node {
            childImageSharp {
            gatsbyImageData(
                placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
        )
      }
        } 
      }
    }

    relatedBlogs: allGhostPost(
    filter: {slug: {in: ["boost-your-business-with-payment-linked-loyalty", "what-is-pos-and-why-is-it-so-important", "how-zealpay-is-made-to-help-you-boost-your-customer-base"]}}
  ) {
    nodes {
        title
      feature_image
      url
      excerpt
      created_at(formatString: "MMM YYYY")
      reading_time
    }
  }
  }
                    `;
export const Head = ({ pageContext }) => {
    const isArabic = pageContext.i18n.language === "ar";
    return <Seo isArabic={isArabic} title={isArabic ? 'Zeal 3.0' : 'Zeal 3.0'} />;
};

